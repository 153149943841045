<template>
	<div>
		<CCard class="p-4 mt-2">
  
  		<CRow class="mb-2">
		  <CCol lg="12">
			<div class="table-responsive-sm table-responsive-md">
			  <CDataTable
				:tableFilter="{ placeholder: 'Search here...' }"
				pagination
				:itemsPerPage="10"
				itemsPerPageSelect
				:loading="isLoading"
				:items="fuel_records_data"
				:fields="fields"
				hover
				striped
				small
				fixed
				custom
			  >
				<template #status="{ item }">
				  <td
					style="padding: 5px; width: 100px; text-transform: uppercase"
				  >
					<CBadge
					  style="margin-top: 10px"
					  :color="getBadge(item.status)"
					  >{{ item.status }}</CBadge
					>
				  </td>
				</template>
				
			  </CDataTable>
			 
			</div>
		  </CCol>
		</CRow>
	  </CCard>
	 
	
	</div>
  </template>
  <script>
  import config from "../../config.js";
  import axios from "../../axios";
  import Swal from "sweetalert2";
  // import Search from './search'
  import Datepicker from "vuejs-datepicker";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import moment from "moment";
  
  export default {
	mounted() {
	  
		this.getData(this.$route.params.id)
	 
	},
	
	watch: {},
	computed: {
	 
	},
	data() {
	  return {
		limit: 10,
		isLoading: false,
		config,
		fields: [
		  {
			key: "liters",
			label: "Liters (L)",
		  },
		  {
			key: "odo_reading",
			label: "Odometer Reading (KM)",
		  },
		  {
			key: "record_date",
			label: "Record Date",
		  },
		  {
			key: "remarks",
			label: "Remarks",
		  },
		  
		 

		],
		currentPage: 1,
		
		
		fuel_records_data: [
		  {
		  	liters: '',
		  	odo_reading: '',
		  	record_date: '',
		  	remarks: ''
		  	
		  }
		 
		],
  
	  };
	},
	name: "Tables",
	components: { Datepicker, vSelect },
	methods: {
		getData(id) {
			axios.get(`${config.api_path}/vehicle/fuel-record/${id}`).then(res => {
				const {data, status} = res.data;

				this.fuel_records_data = data.map(f => {
					f.odo_reading = f.odometer ? f.odometer.reading: '';
					f.remarks = f.remarks ? f.remarks: '-';
					f.record_date = f.date ? moment.unix(f.date).format('MMMM DD, YYYY') : '';
					return f;
				})
			})
		},
	 
	  reloadData() {
		
	  },
	 
	 
  
	
	 
	},
  };
  </script>
  