<template>
	<div>
		<CCard class="p-4 mt-2">
  		<CRow class="mb-2">
		  <CCol lg="12">
			<div class="table-responsive-sm table-responsive-md">
			  <CDataTable
				:tableFilter="{ placeholder: 'Search here...' }"
				pagination
				:itemsPerPage="10"
				itemsPerPageSelect
				:loading="isLoading"
				:items="work_orders_data"
				:fields="fields"
				hover
				striped
				small
				fixed
				custom
			  >
			  <template #wo_no="{ item }">
					<td style="padding: 5px; vertical-align: inherit;">
						<a href="javascript:void()" @click="goToView(item.id)">{{ item.wo_no }}</a>
					</td>
				</template>
			  </CDataTable>
			 
			</div>
		  </CCol>
		</CRow>
	  </CCard>
	 
	
	</div>
  </template>
  <script>
  import config from "../../config.js";
  import axios from "../../axios";
  import Swal from "sweetalert2";
  // import Search from './search'
  import Datepicker from "vuejs-datepicker";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import moment from "moment";

  function toProperCase(str) {
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }
  
  export default {
	mounted() {
	  this.getData(this.$route.params.id)
  
	//   
	},
	
	watch: {},
	computed: {
		fields() {
			if(this.is_trucking()) {
				return this.fields_1;
			}
			return this.fields_2;
		}
	},
	data() {
	  return {
		limit: 10,
		isLoading: false,
		config,
		// for trucking
		fields_1: [
		  {
			key: "wo_no",
			label: "WO No.",
		  },
		  {
			key: "date_reported",
			label: "Date Reported",
		  },
		  {
			key: "scope_of_work",
			label: "Scope of Work",
		  },
		  
		  {
			key: "total_cost",
			label: "Total Cost",
		  },
		  {
			key: "status",
			label: "Status",
		  }
		],

		//not trucking
		fields_2: [
		  {
			key: "wo_no",
			label: "WO No.",
		  },
		  {
			key: "date_needed",
			label: "Date Needed",
		  },
		  {
			key: "wo_type",
			label: "WO Type",
		  },
		  {
			key: "project",
			label: "Project",
		  },
		  {
			key: "service_summary",
			label: "Service Summary",
		  },
		  {
			key: "total_cost",
			label: "Total Cost",
		  },
		  {
			key: "status",
			label: "Status",
		  }
		],
		currentPage: 1,
		
		work_orders_data: [
		  {
		  	wo_no: '',
		  	date_reported: '',
			date_needed: '',
			scope_of_work: '',
		  	total_cost: '',
			status: '',
			wo_type: '',
			project:'',
		  	service_summary: '',
		  }
		 
		]
  
	  };
	},
	name: "Tables",
	components: { Datepicker, vSelect },
	methods: {
	goToView(id) {
		// this.$router.push('/main/view_work_order_trucking/' + id)
		if(this.is_trucking())
			window.open(`#/main/view_work_order_trucking/${id}`)
		else
			window.open(`#/main/view_work_order/${id}`)
	},
	getData(id) {
		axios.get(`${config.api_path}/vehicle/work-order/${id}`).then(res => {
			const {data} = res.data;

			if(!this.is_trucking()) {
				this.work_orders_data = data.map(w => {
					let amount = 0;
					
					w.work_order_part_request.forEach(e => {
						amount += parseFloat(e.total);
					});

					w.work_order_labor_request.forEach(e => {
						amount += parseFloat(e.total);
					});

					w.status = w.status ? toProperCase(w.status) : '';
					w.wo_no = w.wo_no ? `WO-${w.wo_no}` : '';
					w.date_reported = w.date_needed ? moment.unix(w.date_needed).format('MMMM DD, YYYY') : '';
					w.project = w.dept ? w.dept.customer_name : '';
					w.service_summary = w.service_summary ? toProperCase(w.service_summary) : '';
					w.total_cost = this.$options.filters.number_format(parseFloat(amount), 'currency', 2);
					w.wo_type = w.wo_type ? toProperCase(w.wo_type) : '';
					return w;
				})
			}
			else {
				this.work_orders_data = data.map(w => {
					let amount = 0;
					let labor = w.labor_amount ? w.labor_amount : 0;
					w.work_order_part_request.forEach(e => {
						amount += parseFloat(e.total);
					});

					w.status = w.status ? toProperCase(w.status) : '';
					w.wo_no = w.wo_no ? `WO-${w.wo_no}` : '';
					w.date_reported = w.date_needed ? moment.unix(w.date_needed).format('MMMM DD, YYYY') : '';
					w.total_cost = this.$options.filters.number_format((parseFloat(labor) + parseFloat(amount)), 'currency', 2);
					w.service_summary = w.service_summary ? w.service_summary : '';
					return w;
				})
			}
			
		})
	},
	  reloadData() {
		
	  },
	 
	 
  
	
	 
	},
  };
  </script>
  